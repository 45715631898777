import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./fonts.css";
import {
  Box,
  Button,
  Modal,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuLeft from "./components/MenuLeft.js";
import DDoce from "./img/GIF/D_Doce2.gif";
import DTravessura from "./img/GIF/D_Travessura2.gif";
import CDoce from "./img/GIF/C_Doce2.gif";
import CTravessura from "./img/GIF/C_Travessura2.gif";
import MDoce from "./img/GIF/M_Doce2.gif";
import MTravessura from "./img/GIF/M_Travessura2.gif";
import WDoce from "./img/GIF/W_Doce2.gif";
import WTravessura from "./img/GIF/W_Travessura2.gif";
import Sound from "react-sound";
import bgMusic from "./sounds/critec_halloween.mp3";
import { useTheme } from "@mui/material/styles";
import bat from "./img/SVG/bat.svg";

const drawerWidth = 280;

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [department, setDepartment] = useState("");
  const [choice, setChoice] = useState("");
  const [play, setPlay] = useState("");
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleMusic = (type) => {
    setPlay(type);
    setOpen(false);
  };

  const handleDepartment = (name) => {
    setDepartment(name);
  };

  const handleChoice = (name) => {
    setChoice(name);
  };

  return (
    <Grid container>
      <MenuLeft onClick={handleDepartment} choice={handleChoice} />
      <Box
        zIndex={2}
        component="main"
        backgroundColor="#1A1A1A"
        sx={{
          flexGrow: 1,
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "left",
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
        }}
      >
        <Box zIndex={1} class="bg smoke-bg" />
        <Box zIndex={1} class="bg smoke" />
        {department === "" ? (
          <Box minWidth="60vw">
            <Box
              display="flex"
              flexDirection="row"
              textAlign="left"
              alignItems="center"
            >
              <Typography
                sx={{ fontFamily: "RobotoMonoBold", fontSize: "0.8rem" }}
              >
                31 OCT
              </Typography>
              <Box className="dividerMainP" margin={isMobile ? "5px" : "30px"} />
              <Typography
                sx={{ fontFamily: "RobotoMonoBold", fontSize: "0.8rem" }}
              >
                DOÇURA OU TRAVESSURA
              </Typography>
            </Box>
            <Box display="flex">
              <Typography
                sx={{
                  fontFamily: "GabrielaStencilBlack",
                  fontSize: isMobile ? "2.5rem" : isTablet ? "4rem" : "7rem",
                  lineHeight: "100%",
                }}
              >
                HAPPY <br /> HALLOWEEN
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            height="100vh"
            width="100%"
            display="flex"
            flexDirection="column"
            position="relative"
          >
            <Box
              textAlign="center"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box className="divider" margin={isMobile ? "30px" : "50px"}/>
              <Box display="flex" flexDirection="column">
                <Typography
                  fontSize={isMobile ? "0.8rem" : "1rem"}
                  fontFamily="RobotoMonoBold"
                  lineHeight="100%"
                >
                  31 OCT
                  <br />
                  00:00
                </Typography>
              </Box>
              <Box className="divider" margin={isMobile ? "30px" : "50px"} />
            </Box>
            <Box textAlign="center">
              <Typography
                fontFamily="GabrielaStencilBlack"
                fontSize={isMobile ? "2rem" : "4rem"}
                lineHeight={isMobile && "100%"}
              >
                HAPPY HALLOWEEN
              </Typography>
              <Typography
                sx={{ textTransform: "uppercase" }}
                fontFamily="RobotoMonoBold"
                fontSize="1.5rem"
              >
                {department} ROOM
              </Typography>
            </Box>
            <Box
              marginTop="1%"
              width="100%"
              flexDirection="row"
              display={choice === "" ? "flex" : "none"}
              justifyContent="center"
              alignItems="center"
              height="100%"
              columnGap={isMobile ? 3 : 5}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  width: isMobile ? "150px" : "300px",
                  height: "60px",
                  backgroundColor: "#fff",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#Fbb03b",
                    color: "#fff",
                  },
                }}
                onClick={() => handleChoice("doce")}
              >
                <Typography
                  fontFamily="RobotoMonoBold"
                  fontSize={isMobile ? "0.8rem" : "1.5rem"}
                >
                  DOÇURA
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  height: "60px",
                  width: isMobile ? "150px" : "300px",
                  backgroundColor: "#fff",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#Fbb03b",
                    color: "#fff",
                  },
                }}
                onClick={() => handleChoice("travessura")}
              >
                <Typography
                  fontFamily="RobotoMonoBold"
                  fontSize={isMobile ? "0.8rem" : "1.5rem"}
                >
                  TRAVESSURA
                </Typography>
              </Button>
            </Box>
            <Box display={"flex"} justifyContent="center" alignItems="center">
              {choice === "" ? (
                <img
                  style={{ display: "none" }}
                  src={DDoce}
                  alt=""
                  width={isMobile ? "100%" : isTablet ? "100%" : "25%"}
                />
              ) : choice === "doce" ? (
                <img
                  src={
                    department === "Design"
                      ? DDoce
                      : department === "Web"
                      ? WDoce
                      : department === "Comercial"
                      ? CDoce
                      : department === "Marketing"
                      ? MDoce
                      : null
                  }
                  alt=""
                  width={isMobile ? "100%" : isTablet ? "100%" : "25%"}
                />
              ) : (
                choice === "travessura" && (
                  <img
                    src={
                      department === "Design"
                        ? DTravessura
                        : department === "Web"
                        ? WTravessura
                        : department === "Comercial"
                        ? CTravessura
                        : department === "Marketing"
                        ? MTravessura
                        : null
                    }
                    alt=""
                    width={isMobile ? "100%" : isTablet ? "100%" : "25%"}
                  />
                )
              )}
            </Box>

            <Box textAlign="center" width="100%" position="absolute" bottom="0" right="0" marginBottom={isMobile ? "100px" : "5%"}>
              <Typography
                fontFamily="RobotoMonoBold"
                fontSize={isMobile ? "0.8" : "1.5rem"}
              >
                {department === "Design"
                  ? "Brainstorming para inspiração"
                  : department === "Web"
                  ? "Falta apenas uma linha de código"
                  : department === "Comercial"
                  ? "Uma proposta irrecusável"
                  : department === "Marketing"
                  ? "Vamos fazer uma campanha viral"
                  : null}
              </Typography>
            </Box>
          </Box>
        )}
        <Sound
          url={bgMusic}
          autoPlay={true}
          playStatus={play === "PLAYING" ? "PLAYING" : "PAUSED"}
          volume={40}
          loop
          ignoreMobileRestrictions={true}
        />
        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "70vw",
                height: "70vh",
                bgcolor: "#F7931E",
                outline: 0,
                p: 4,
                textTransform: "uppercase",
              }}
            >
              <Box width="100%" textAlign="center">
                <img src={bat} alt="" width="30%" />
                <Typography
                  id="modal-modal-title"
                  fontFamily="RobotoMonoBold"
                  fontSize={isMobile ? ".6rem" : "1rem"}
                >
                  Doçura ou Travessura
                </Typography>
                <Typography
                  fontFamily="GabrielaStencilBlack"
                  fontSize={isMobile ? "1rem" : "3rem"}
                >
                  HAPPY HALLOWEEN
                </Typography>
              </Box>
              <Box width="100%" textAlign="center">
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  fontFamily="RobotoMonoBold"
                  fontSize={isMobile ? ".8rem" : "1rem"}
                >
                  Esta experiência utiliza recursos áudio.
                </Typography>
                <Typography
                  fontFamily="RobotoMonoBold"
                  fontSize={isMobile ? ".8rem" : "1rem"}
                >
                  Os vampiros vão sugar os teus dados móveis.
                </Typography>
                <Typography
                  fontFamily="RobotoMonoBold"
                  fontSize={isMobile ? ".8rem" : "1rem"}
                >
                  Aceita o desafio?
                </Typography>
              </Box>
              <Box
                width="100%"
                pt={10}
                flexDirection="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
                columnGap={isMobile ? 2 : 5}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    width: isMobile ? "150px" : "300px",
                    height: isMobile ? "40px" : "60px",
                    backgroundColor: "#fff",
                    color: "#000",
                    "&:hover": {
                      backgroundColor: "#Fbb03b",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleMusic("PLAYING")}
                >
                  <Typography
                    fontFamily="RobotoMonoBold"
                    fontSize={isMobile ? "0.8rem" : "1rem"}
                  >
                    ACEITAR
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    height: isMobile ? "40px" : "60px",
                    width: isMobile ? "150px" : "300px",
                    backgroundColor: "#fff",
                    color: "#000",
                    "&:hover": {
                      backgroundColor: "#Fbb03b",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleMusic("STOPPED")}
                >
                  <Typography
                    fontFamily="RobotoMonoBold"
                    fontSize={isMobile ? "0.8rem" : "1rem"}
                  >
                    RECUSAR
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Grid>
  );
}

export default App;
