import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import bat from "../img/SVG/bat.svg";
import critec from "../img/SVG/critec.svg";

const drawerWidth = 150;

const MenuLeft = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDepartment = (name) => {
    props.onClick(name);
    props.choice("");
    handleDrawerToggle();
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "transparent",
          boxShadow: "none",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                background: "rgb(241,90,36)",
                background:
                  "linear-gradient(180deg, rgba(241,90,36,1) 0%, rgba(251,176,59,1) 100%)",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onMouseDown={() => handleDepartment("")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <img src={bat} alt="bat" width="100px" />
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Design")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="60px"
                    height="60px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize="0.8rem" fontFamily="RobotoMonoBold">
                      D
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold" fontSize="0.8rem">
                    DESIGN
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Web")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="60px"
                    height="60px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize=".8rem" fontFamily="RobotoMonoBold">
                      W
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold" fontSize="0.8rem">
                    WEB
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Marketing")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="60px"
                    height="60px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize=".8rem" fontFamily="RobotoMonoBold">
                      M
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold" fontSize=".8rem">
                    MARKETING
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Comercial")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="60px"
                    height="60px"
                    backgroundColor="#1a1a1a"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize=".8rem" fontFamily="RobotoMonoBold">
                      C
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold" fontSize=".8rem">
                    COMERCIAL
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <a href="https://www.critec.pt/inicio" target="_blank">
                  <img src={critec} alt="critec logo" width="100px" />
                </a>
              </Box>
            </Box>
          </Drawer>{" "}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                background: "rgb(241,90,36)",
                background:
                  "linear-gradient(180deg, rgba(241,90,36,1) 0%, rgba(251,176,59,1) 100%)",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onMouseDown={() => handleDepartment("")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <img src={bat} alt="bat" width="125px" />
              </Box>
              <Box>
                <Box
                  textAlign="center"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Design")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="80px"
                    height="80px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize="1.5rem" fontFamily="RobotoMonoBold">
                      D
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold">DESIGN</Typography>
                </Box>
                <Box
                  textAlign="center"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Web")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="80px"
                    height="80px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize="1.5rem" fontFamily="RobotoMonoBold">
                      W
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold">WEB</Typography>
                </Box>
                <Box
                  textAlign="center"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Marketing")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="80px"
                    height="80px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize="1.5rem" fontFamily="RobotoMonoBold">
                      M
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold">MARKETING</Typography>
                </Box>
                <Box
                  textAlign="center"
                  sx={{
                    padding: "1rem",
                    "&:hover": { color: "#fff", cursor: "pointer" },
                  }}
                  onMouseDown={() => handleDepartment("Comercial")}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#1a1a1a"
                    width="80px"
                    height="80px"
                    sx={{
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        mixBlendMode: "screen",
                      },
                    }}
                  >
                    <Typography fontSize="1.5rem" fontFamily="RobotoMonoBold">
                      C
                    </Typography>
                  </Box>
                  <Typography fontFamily="RobotoMonoBold">COMERCIAL</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <a href="https://www.critec.pt/inicio" target="_blank">
                  <img src={critec} alt="critec logo" width="125px" />
                </a>
              </Box>
            </Box>
          </Drawer>
        </Drawer>
      </Box>
    </Box>
  );
};

export default MenuLeft;
